<template>
    <div class="page-wrapper">
        <div class="page-container">
            <div class="content-container" @click="hideSelection">
                <div class="vaulted-methods">
                    <div class="payment-method" :class="{ 'active' : method == selectedpaymentmethod }" v-for="method in vaultedmethods" :key="method.data.reference">
                        <div v-if="method.data.favourite" class="default-label">
                            <label>DEFAULT</label>
                        </div>
                        <label :for="method.data.reference">
                            <img v-if="method.method === 0" src="@/assets/icons/paypal_logo.png" id="paypal-logo"/>
                            <img v-if="method.method === 1" src="@/assets/icons/visa.svg"        id="visa-logo"/>
                            <img v-if="method.method === 2" src="@/assets/icons/sepa_logo.svg"   id="sepa-logo"/>
                            <!-- method 3 googlePay -->
                            <!-- method 4 applePay -->
                            <img v-if="method.method === 5" src="@/assets/icons/mastercard_logo.svg" id="mastercard-logo"/>
                            <span class="vaulted-info">{{ method.data.description }}</span>
                        </label>
                        <input type="radio" :id="method.data.reference" name="payment" :value="method" v-model="selectedpaymentmethod">
                    </div>
                </div>

                <div class="selection" :class="{ 'active' : this.showselection }">
                    <div class="paypal-button" v-if="methodIsAvailable(0)" :id="containerid" :class="{ 'hidden' : hidepaypalbutton }"></div>

                    <div class="payment-method" v-if="methodIsAvailable(5)" @click="vault(PAYMENT_METHODS.MASTERCARD)">
                        <label>
                            <img src="@/assets/icons/mastercard_logo.svg" id="mastercard-logo"/>
                        </label>
                    </div>

                    <div class="payment-method" v-if="methodIsAvailable(1)" @click="vault(PAYMENT_METHODS.VISA)">
                        <label>
                            <img src="@/assets/icons/visa.svg" id="visa-logo"/>
                        </label>
                    </div>

                    <div class="payment-method" v-if="methodIsAvailable(2)" @click="vault(PAYMENT_METHODS.SEPA)">
                        <label>
                            <img src="@/assets/icons/sepa_logo.svg" id="sepa-logo"/>
                        </label>
                    </div>
                </div>

                <form :id="telecashformid" method="post" :action="telecashurl">
                    <input type="hidden" name="chargetotal" value="0.0" />
                    <input type="hidden" name="checkoutoption" value="combinedpage"/>
                    <input type="hidden" name="currency" value="978" />
                    <input type="hidden" name="hash_algorithm" value="HMACSHA256" />
                    <input type="hidden" name="mode" value="payonly" />
                    <input type="hidden" name="paymentMethod" :value="customer?.telecash?.method" />
                    <input type="hidden" name="responseFailURL" :value="customer?.telecash?.returnurl"/>
                    <input type="hidden" name="responseSuccessURL" :value="customer?.telecash?.returnurl"/>
                    <input type="hidden" name="storename" :value="storename" />
                    <input type="hidden" name="timezone" value="Europe/Berlin" />
                    <input type="hidden" name="txndatetime" :value="customer?.telecash?.transactiontime" />
                    <input type="hidden" name="txntype" value="preAuth" />
                    <input type="hidden" name="hashExtended" :value="customer?.telecash?.transactionhash"/>
                    <input type="hidden" name="assignToken" value="true"/>
                </form>
            </div>

            <Popup :isopen="showsepahint" @close="() => showsepahint = false" cancelbuttonclass="popup-cancel" submitlabel="Ok">
                Um eine SEPA Lastschrift als Bezahlmethode zu speichern, wählen Sie beim bezahlen per Lastschrift bitte 'Bezahlmethode speichern'
            </Popup>
        </div>
        <div class="content-width pay-button-container" :class="{ 'hide' : showselection }">
            <div class="add-methods" >
                <div class="actions">
                    <div class="favour-button" v-if="this.paymentinstance.psp === 3" v-show="selectedpaymentmethod && !selectedpaymentmethod.data.favourite" @click="favour(selectedpaymentmethod)">
                        <label>Als Default setzen</label>
                    </div>
                    <div class="delete-button" v-show="selectedpaymentmethod && !selectedpaymentmethod.data.favourite" @click="unvault(selectedpaymentmethod)">
                        <label>Löschen</label>
                    </div>
                    <label class="delete-hint" v-show="selectedpaymentmethod?.data?.favourite">
                        Die favorisierte Bezahlart kann nicht gelöscht werden.
                    </label>
                </div>
                <div class="add-button" @click="showSelection">
                    <v-icon name="plus" scale="1.2"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/plus'

import Vue from 'vue'

import Popup from '@/components/systems/elements/Popup.vue'

import {
    QUERY_PARAMS,
} from '@/classes/QUERY_PARAMS.js'

import Payments, {
    PAYMENT_METHODS,
    PAYMENT_SERVICE_PROVIDERS
} from '@/lib/Payment'

export default {
    name: 'vaulting-page',
    data() {
        return {
            PAYMENT_METHODS,

            paymentinstance: Payments,
            containerid: 'paypal-button',
            selectedpaymentmethod: undefined,
            oidtoken: undefined,
            vaultedmethods: [],
            showselection: false,
            showsepahint: false,
            telecashformid: 'telecash-form'
        }
    },
    computed: {
        config() {
            return this.$config
        },
        isloading: {
            set(value) {
                this.$root.$data.loading = value
            },
            get() {
                return this.$root.$data.loading
            }
        },
        customer() {
            return this.paymentinstance?.customer
        },
        newcustomer() {
            return !this.customer?.braintree.id
        },
        hidepaypalbutton() {
            const newcustomer = !this.customer?.braintree.id
            const haspaypalmethod = this.vaultedmethods.find(method => method.origin === 1)
            const hasbraintreemethod = !!this.customer?.braintree.methods && this.customer?.braintree.methods.length > 0

            // origin 1 === braintree -> we prevent adding more than one paypal account
            return newcustomer && haspaypalmethod && hasbraintreemethod
        },
        telecashurl() {
            return this.$config.telecashconfig.url
        },
        storename() {
            return this.$config.telecashconfig.storename
        },
        provider() {
            if (this.config.paymentservices.provider === 'tc') {
                return PAYMENT_SERVICE_PROVIDERS.TELECASH
            } else if (this.config.paymentservices.provider === 'lp') {
                return PAYMENT_SERVICE_PROVIDERS.LOGPAY
            } else {
                return undefined
            }
        },
    },
    async mounted() {
        this.isloading = true

        const oidtoken = this.$route.query.token

        const success  = this.$route.query[QUERY_PARAMS.SUCCESS]
        const error    = this.$route.query[QUERY_PARAMS.ERROR]
        const ogmethod = this.$route.query[QUERY_PARAMS.OG_METHOD]

        // telecash return handling
        const telecashsuccess = success && parseInt(this.$route.query[QUERY_PARAMS.PSP], 10) === 8 // 8 = MerchantServer TeleCash
        const hosteddataid    = this.$route.query[QUERY_PARAMS.TELECASH_HOSTED_DATA]
        const cardno          = this.$route.query[QUERY_PARAMS.TELECASH_CARD_NO]

        // logpay return handling
        const logpaysuccess = success && parseInt(this.$route.query[QUERY_PARAMS.PSP], 10) === 3 // 3 = MerchantServer LogPay
        const logpayconfirm = this.$route.query[QUERY_PARAMS.LOGPAY_CONFIRM_USER]
        const logpayuserid  = this.$route.query[QUERY_PARAMS.LOGPAY_USER_ID]

        // we can not load the logpayuser before the vaulting was confirmed,
        // therefore we can not instantiate the paymentinstance before confirming
        if (logpaysuccess && logpayconfirm) {
            try {
                await Payments.confirmLogPayVault(logpayuserid)
                this._returnPaymentResult({ success: 'done', psp: 3, location, oidtoken })
            } catch(err) {
                this._returnPaymentResult({ error: err, location })
            }
        }

        if (oidtoken) {
            // if the oidtoken is not supplied, we also won't need the paymentinstance
            this.paymentinstance = await this.initPaymentInstance(oidtoken)
        }

        if (telecashsuccess && ogmethod) {
            try {
                //get the userprofile via a new payments instance
                await this.paymentinstance.createOrUpdateTeleCashMethod(ogmethod, hosteddataid, cardno)
                this._returnPaymentResult({ success: 'done', psp: 8, location, oidtoken: this.paymentinstance.oidtoken })
            } catch(err) {
                this._returnPaymentResult({ error: err, location })
            }

            return
        // else we only redirect with a success
        } else if (success && success !== 'done') {
            this._returnPaymentResult({ success: 'done', psp: 8, location, oidtoken: this.paymentinstance.oidtoken })
        } else if (error) {
            // the middleware already sends an error in the url
            return
        } else {
            if (!this.paymentinstance?.oidtoken) {
                throw 'user missing'
            }

            this.init()
            .then(() => {
                this.isloading = false
            })
            .catch(err => {
                console.log('Error while mounting')
                this._returnPaymentResult({ error: err, location })
            })
        }
    },
    methods: {
        initPaymentInstance: async function(oidtoken) {
            try {
                return await new Payments(oidtoken, this.provider, true, true).init()
            } catch(error) {
                this._returnPaymentResult({ error, location })
            }
        },
        init: async function(reinit) {
            this.vaultedmethods = []
            this.selectedpaymentmethod = undefined
            this.showselection = false

            if (reinit) {
                // reinit the payments instance if needed
                this.paymentinstance = await this.initPaymentInstance(this.paymentinstance.oidtoken)
            }

            if (!this.hidepaypalbutton) {
                await this.initAgreementPaypalButton()
            }

            // Telecash
            if (this.provider === PAYMENT_SERVICE_PROVIDERS.TELECASH) {
                this.customer.telecash.methods?.forEach(ref => {
                    this.addVaultedMethod(ref)
                })

            // Logpay
            } else if (this.provider === PAYMENT_SERVICE_PROVIDERS.LOGPAY) {
                this.customer.logpay.methods?.forEach(ref => {
                    this.addVaultedMethod(ref)
                })
            }
        },
        methodIsAvailable(method) {
            switch(method) {
                case PAYMENT_METHODS.PAYPAL:
                    return this.config.paymentservices.paypal
                case PAYMENT_METHODS.VISA:
                    return this.config.paymentservices.cc
                case PAYMENT_METHODS.MASTERCARD:
                    return this.config.paymentservices.cc
                case PAYMENT_METHODS.SEPA:
                    return this.config.paymentservices.sepa
                case PAYMENT_METHODS.GOOGLEPAY:
                    return this.config.paymentservices.googlepay
                case PAYMENT_METHODS.APPLEPAY:
                    return this.config.paymentservices.applepay
                default:
                    return false;
            }
        },
        addVaultedMethod: function(vaultedmethod) {
            // only show the vaulted methods to the configured provider
            if (vaultedmethod.psp === this.provider && vaultedmethod.method !== undefined && this.methodIsAvailable(vaultedmethod.method)) {
                this.vaultedmethods.push(vaultedmethod)
            }
        },
        initAgreementPaypalButton: function() {
            this.isloading = true

            return this.paymentinstance.getPayPalVaultInstance()
            .then(paypalinstance => {
                let innerthis = this

                return paypal.Buttons({
                    style: {
                        size:    'large',
                        color:   'blue',
                        shape:   'rect',
                        label:   'paypal',
                        tagline: 'false'
                    },
                    fundingSource: paypal.FUNDING.PAYPAL,
                    createBillingAgreement: function() {
                        return paypalinstance.createPayment({
                            flow: 'vault',
                            billingAgreementDescription: '', // TODO:
                        })
                    },
                    onApprove: function(data) { // also available: actions
                        innerthis.isloading = true

                        return paypalinstance.tokenizePayment(data)
                        .then(async payload => {
                            try {
                                await innerthis.paymentinstance.vaultPayPalAgreement(payload.nonce)
                                await innerthis.init(true)
                                innerthis.isloading = false
                            } catch(err) {
                                innerthis._returnPaymentResult({ error: err, location })
                            }
                        })
                    },
                    onInit: function() { // data, actions
                        innerthis.isloading = false
                    },
                    onCancel: function() { // data, actions
                        console.log('PayPal vaulting canceled');
                    },
                    onError: function(err) {
                        innerthis._returnPaymentResult({ error: err, location })
                    }
                })
                .render('#' + this.containerid)
            })
            .catch(err => {
                this._returnPaymentResult({ error: err, location })
            })
        },
        showSelection(event) {
            event.stopPropagation()
            this.showselection = true
        },
        hideSelection() {
            this.showselection = false
        },
        unvault: async function(method) {
            this.isloading = true

            try {
                await this.paymentinstance.unvault(method.origin, method.method, method.data.reference, method.data.favourite)
                await this.init(true)
            } catch(err) {
                console.log('err', err)

                const msg = err?.response?.data ? err.response.data : err.message
                this._returnPaymentResult({ error: msg || err, location })
            }

            this.isloading = false
        },
        favour: async function(method) {
            this.isloading = true

            try {
                await this.paymentinstance.favour(method.data.reference)
                await this.init(true)
            } catch(err) {
                this._returnPaymentResult({ error: err, location })
            }

            this.isloading = false
        },
        vault: async function(method) {
            this.isloading = true

            if (this.provider === PAYMENT_SERVICE_PROVIDERS.TELECASH) {
                if (method === PAYMENT_METHODS.SEPA) {
                    this.showsepahint = true
                } else {
                    await this.vaultTeleCash(method)
                }
            } else if (this.provider === PAYMENT_SERVICE_PROVIDERS.LOGPAY) {
                await this.vaultLogPay(method)
            }

            this.isloading = false
        },
        vaultLogPay: async function(method) {
            try {
                window.location.href = await this.paymentinstance.vaultLogPay(method)
            } catch(err) {
                this._returnPaymentResult({ error: err.message, location })
            }
        },
        vaultTeleCash: async function(method) {
            let returnurl = Vue.middleware() + '/payment/telecash/hpp/return'
            returnurl += `?${QUERY_PARAMS.TOKEN}=${this.paymentinstance.oidtoken}`
            returnurl += `&${QUERY_PARAMS.OG_METHOD}=${method}`
            returnurl += '&vault=true'

            try {
                await this.paymentinstance.prepareTeleCash(method, returnurl)
                document.getElementById(this.telecashformid).submit()
            } catch(err) {
                this._returnPaymentResult({ error: err.message, location })
            }
        },
    },
    components: {
        Popup
    }
}
</script>

<style scoped>
.page-wrapper {
    background: #31525F;
}

.pay-button-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transition: opacity 0.2s ease 0.3s;
}

.pay-button-container.hide {
    opacity: 0;
    visibility: hidden;
    transition: none;
}

.content-container {
    position: relative;
    padding-bottom: 110px;
}

.hidden {
    display: none;
}

/* ------------------------- */
.payment-method {
    width: 100%;
    height: 50px;
    border-radius: 8px;
    text-align: left;
    background: #43616d;
    margin-top: 10px;
    position: relative;
    box-sizing: unset;
}

.payment-method > .default-label {
    position: absolute;
    background: green;
    color: #fff;
    font-weight: bold;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 1px 6px;
    right: 0;
}

.payment-method:hover,
.payment-method:hover > *,
.payment-method:hover > * > * {
    cursor: pointer;
}

.payment-method.active {
    border: 2px solid #c94191;
}

.payment-method > label {
    color: #fff;
    padding: 5px 20px;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.payment-method > input {
    visibility: hidden;
}

.payment-method > label > img {
    box-sizing: border-box;
    height: 50px;
    object-fit: contain;
}

.payment-method > label > .vaulted-info {
    font-size: 1.2em;
}

#paypal-logo {
    padding: 10px;
}

#sepa-logo {
    background: #fff;
    padding: 5px;
    height: 30px;
}

#visa-logo {
    padding: 10px;
}

#mastercard-logo {
    padding: 5px;
}

.add-methods {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100px;
}

.add-methods > .actions > .delete-button {
    background-color: red;
    color: #fff;
    border-radius: 8px;
    font-weight: bold;
    font-size: 1.5em;
    margin: 10px 0;
    margin-right: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: center;
    width: 180px;
}

.add-methods > .actions > .delete-hint {
    border-color: red;
    color: red;
    font-size: 1.25em;
    font-weight: bold;
    margin-right: 20px;
    background-color: #00000055;
    border-radius: 8px;
    display: block;
    padding: 4px 8px;
}

.add-methods > .actions > .delete-button:hover,
.add-methods > .actions > .delete-button:hover > * {
    cursor: pointer;
}

.add-methods > .actions > .favour-button {
    background-color: green;
    color: #fff;
    border-radius: 8px;
    font-weight: bold;
    font-size: 1.5em;
    margin: 10px 0;
    margin-right: 40px;
    height: 30px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    justify-content: center;
    width: 180px;
}

.add-methods > .actions > .favour-button:hover,
.add-methods > .actions > .favour-button:hover > * {
    cursor: pointer;
}

.add-methods > .add-button {
    min-width: 70px;
    min-height: 70px;
    border-radius: 50%;
    background: #c94191;
    fill: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5px;
}

.add-methods > .add-button:hover {
    cursor: pointer;
}

.selection {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: fit-content;
    background: #31525f;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0px -3px 5px rgb(0 0 0 / 30%);
    max-height: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease-out, padding 0.5s ease;
}

.selection.active {
    padding: 20px;
    max-height: 300px;
}

*:deep(.popup-cancel) {
    display: none;
}
</style>