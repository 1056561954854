import {
    PAYMENT_SERVICE_PROVIDERS,
    PAYMENT_METHODS,
    PAYMENT_ORIGINS,
    BACKEND_PAYMENT_METHOD
} from './enums/index.js'

import {
    getLogPayCustomer,
    createLogPayUser,
    createOrUpdateLogPayReference,
    vaultLogPayPayPal,
    vaultLogPayMethod,
    confirmLogPayVault,
    getDirectPaymentUrl,
    vaultedLogPayPurchase,
    disableLogPayMethod,
    favourLogPayMethod
} from './scripts/logpayadapter.js'

import {
    BRAINTREE_PSP_NAME,
    getBraintreeCustomer,
    createBraintreeCustomer,
    getPayPalVaultInstance,
    vaultPaypalAgreement,
    getDeviceData,
    removePaymentMethod,
    vaultedPurchase
} from './scripts/braintreeadapter.js'

import {
    getNpsUserProfile,
    getNpsAdressData
} from './scripts/profileadapter.js'

import {
    createOrUpdateTeleCashMethod,
    removeTeleCashMethod,
    getTransactionHash
} from './scripts/telecashadapter.js'

// re-export relevant objects
export { 
    BRAINTREE_PSP_NAME,
    PAYMENT_METHODS,
    PAYMENT_SERVICE_PROVIDERS,
    PAYMENT_ORIGINS,
    BACKEND_PAYMENT_METHOD
}

class VaultedMethod {
    constructor(origin, psp, method, data = { description: undefined , reference: undefined, methodtoken: undefined }) {
        this.origin = origin
        this.psp = psp
        this.method = method
        this.data = data
    }
}

class BraintreeCustomer {
    constructor(id = undefined, methods = []) {
        this.id      = id
        this.methods = methods
    }
}

class LogPayCustomer {
    constructor(id = undefined, methods = []) {
        this.id      = id
        this.methods = methods
    }
}

class TeleCashCustomer {
    constructor(methods = [], returnurl = undefined, transactiontime = undefined, method = undefined, transactionhash = undefined, hosteddataid = undefined, savehosteddataid = true) {
        this.methods          = methods
        this.returnurl        = returnurl
        this.transactiontime  = transactiontime
        this.method           = method
        this.transactionhash  = transactionhash
        this.hosteddataid     = hosteddataid
        this.savehosteddataid = savehosteddataid
    }

    resetTransactionData() {
        this.returnurl        = undefined
        this.transactiontime  = undefined
        this.method           = undefined
        this.transactionhash  = undefined
        this.hosteddataid     = undefined
        this.savehosteddataid = true
    }
}

class PaymentCustomer {
    constructor(firstname = undefined, lastname = undefined, email = undefined, profile = undefined, address = undefined, braintree = new BraintreeCustomer(), logpay = new LogPayCustomer(), telecash = new TeleCashCustomer()) {
        this.firstname = firstname
        this.lastname  = lastname
        this.email     = email
        this.profile   = profile
        this.address   = address
        this.braintree = braintree
        this.logpay    = logpay
        this.telecash  = telecash
    }
}

export default class Payments {
    constructor(oidtoken, psp, createBraintreeUser = false, createLogPayUser = false) {
        this.oidtoken = oidtoken
        this.psp = psp
        this.createBraintreeUser = createBraintreeUser
        this.createLogPayUser = createLogPayUser
        this.customer = new PaymentCustomer()
    }

    /**
     * Initializes braintree and either logpay or telecash
     * with all needed steps (account registation etc.)
     * and fills the customer object
     * 
     * @returns {Promise<Payments>} a promise with this, the instance itself
     */
    async init() {
        if (!this.oidtoken || this.oidtoken.trim() === '') {
            throw new Error('no-token')
        }

        // get nps user and profile
        const userprofileresponse = await getNpsUserProfile(this.oidtoken)

        this.customer.profile = userprofileresponse.data
        this.customer.firstname = this.customer.profile.info.firstname
        this.customer.lastname  = this.customer.profile.info.lastname
        this.customer.email     = this.customer.profile.info.email

        // in case of logpay we need a valid billing address
        if (this.psp === PAYMENT_SERVICE_PROVIDERS.LOGPAY) {
            // get the user address data
            const addressresponse = await getNpsAdressData(this.oidtoken)

            const address = addressresponse.data
            const birthdate = this.customer.profile.properties.personalInformation.dateOfBirth

            if (   !address
                || !birthdate
                || !address.strasse
                || !address.hausNr
                || !address.plz
                || !address.ort
                || !address.land
                || !this.customer.firstname
                || !this.customer.lastname
                || !this.customer.email) {
                throw new Error('profile-incomplete')
            }

            this.customer.address = address
        }

        this.customer = await initBraintree(this.customer, this.oidtoken, this.psp, this.createBraintreeUser)

        // logpay methods are stored with the logpay user
        if (this.psp === PAYMENT_SERVICE_PROVIDERS.LOGPAY) {
            this.customer = await initLogPay(this.customer, this.oidtoken, this.createLogPayUser)
        } else if (this.psp === PAYMENT_SERVICE_PROVIDERS.TELECASH) {
            this.customer = initTeleCash(this.customer)
        }

        return new Promise(res => res(this))
    }

    /**
     * Saves a given TeleCash method to the nps user
     * 
     * @param {PAYMENT_METHODS} method the payment method to save in the nps
     * @param {String} hosteddataid the payment reference from telecash
     * @param {String} cardno the anonnymous cardno to display (nps: description)
     * @returns {ProfileBean} the nps user profile
     */
    async createOrUpdateTeleCashMethod(method, hosteddataid, cardno) {
        const backendmethod = mapInternalToMerchantServerPaymentMethod(method)
        return createOrUpdateTeleCashMethod(this.oidtoken, this.customer.profile, backendmethod, hosteddataid, cardno)
    }

    /**
     * Creates a paypal sdk instance that can be used to render a payment button
     * 
     * @return {Promise<PayPalSdk>} a promise with the paypal sdk instance
     */
    async getPayPalVaultInstance() {
        return getPayPalVaultInstance(this.customer.braintree.id)
    }

    /**
     * Vaults a paypal billing agreement.
     * For TeleCash this happens solely with braintree
     * For LogPay the agreement is also saved to the logpay user
     * 
     * @param {String} paymentnonce the payment nonce obtained from creating a paypal billing agreement
     * @returns {Promise<?>} a promise with an arbitrary value for Promise chaining. Getting a promise means success
     */
    async vaultPayPalAgreement(paymentnonce) {
        const paypalcustomerid = this.customer.braintree.id

        // vault the agreement with braintree
        const vaultingresponse = await vaultPaypalAgreement(paypalcustomerid, paymentnonce)

        // for logpay we need to update the logpay customer
        // with the billing agreement
        if (this.psp === PAYMENT_SERVICE_PROVIDERS.LOGPAY) {
            const agreementid = vaultingresponse.data.billingagreementid
            const methodemail = vaultingresponse.data.email

            const devicedata  = await getDeviceData(paypalcustomerid)
            const customerid  = this.customer.logpay.id
            const oidtoken    = this.oidtoken

            // TODO: if this goes wrong, we have to remove the method from the braintree account!
            await vaultLogPayPayPal(customerid, oidtoken, methodemail, agreementid, devicedata)
        }

        // resolve with undefined for async use
        return new Promise(res => res(undefined))
    }

    /**
     * Unvaults a given method for the configured psp
     * 
     * @param {PAYMENT_ORIGINS} origin the origin of the method (set by mapNpsRefrenceToVaultedMethod) to identify Braintree cases
     * @param {PAYMENT_METHODS} ogmethod the internal payment method to vault
     * @param {String} reference the description to set for the method in the nps
     * @param {boolean} favourite favourite state of the method
     * @returns {Promise<?>} a promise with an arbitrary value for promise chaining. Getting a promise means success
     * 
     * @throws {no-methods} if the logpay user does not have any methods
     * @throws {last-method} if the method to unvault is the last method -> logpay does not allow deactivation
     * @throws {method-is-favourite} if the method is the currently active (favourite) method
     */
    async unvault(origin, ogmethod, reference, favourite) {
        if (this.psp === PAYMENT_SERVICE_PROVIDERS.TELECASH) {
            return await removeVaultedTelecash(this.customer, this.oidtoken, origin, ogmethod, reference) // TODO
        } else if (this.psp === PAYMENT_SERVICE_PROVIDERS.LOGPAY) {
            if (!this.customer?.logpay?.methods) {
                throw 'no-methods'
            } else if (this.customer.logpay.methods.length <= 1) {
                throw 'last-method'
            } else if (favourite) {
                throw 'method-is-favourite'
            } else {
                return await disableLogPayMethod(this.customer.logpay.id, reference)
            }
        }
    }

    /**
     * Favours the given LogPay method
     * 
     * @param {String} reference the logpay method id to favour
     * @returns {Promise<undefined>} empty promise
     * 
     * @throws {not-available} if called with telecash configured as psp
     */
    async favour(reference) {
        if (this.psp === PAYMENT_SERVICE_PROVIDERS.LOGPAY) {
            return await favourLogPayMethod(this.customer.logpay.id, reference)
        } else {
            throw 'not-available'
        }
    }

    /**
     * Sets up the vaulting with LogPay and returns the url to logpay
     * to which the user must be redirected in order to vault the method
     * 
     * @param {PAYMENT_METHODS} ogmethod the internal method to vault
     * @returns {Promise<String>} promise with the redirect url
     */
    async vaultLogPay(ogmethod) {
        return vaultLogPay(this.customer, this.oidtoken, ogmethod)
    }

    /**
     * STATIC! function for confirming a vaulted method for a given LogPay user
     * TODO: the LogPay user can not be loaded with a pending, unconfirmed vaulting request
     *       -> because of that, this function is static and must be called before
     *          a Payments object can be instantiated
     *       => relocate this flow to the middleware!
     * 
     * @param {String} logpayuserid the logpayuser which needs a confimration
     * @returns {Promise<?>} a promise with an arbitrary value for Promise chaining. Getting a promise means success
     */
    static async confirmLogPayVault(logpayuserid) {
        return confirmLogPayVault(logpayuserid)
    }

    /**
     * Sets up the customer.telecash object for vaulting
     * Gets and sets returnurl, transactiontime, method and the transactionhash
     * The values can be used to fill the telecash form before submitting
     * 
     * @param {PAYMENT_METHODS} ogmethod method to vault
     * @param {String} returnurl url that will be used as a redirect url in the telecash form
     * @param {Boolean = true} savehosteddataid defaults to true for vaulting
     * @returns {Promise<?>} a promise with an arbitrary value for Promise chaining. Getting a promise means success
     */
    async prepareTeleCash(ogmethod, returnurl, vault = true, amount = undefined, vaultedpurchase = false, assigntoken = true) {
        this.customer.telecash.resetTransactionData()

        this.customer.telecash.method = mapToTeleCashPaymentMethod(ogmethod)
        
        if (!this.customer.telecash.method) {
            throw new Error('prepareTeleCash | Unknown payment method: ' + ogmethod)
        }

        let now = new Date()
        // Y:m:d-H:i:s
        const year    = now.getFullYear()
        const month   = ('0' + (now.getMonth()+1)).slice(-2)
        const day     = ('0' + now.getDate()).slice(-2)
        const hours   = ('0' + now.getHours()).slice(-2)
        const minutes = ('0' + now.getMinutes()).slice(-2)
        const seconds = ('0' + now.getSeconds()).slice(-2)
        
        this.customer.telecash.transactiontime  = `${year}:${month}:${day}-${hours}:${minutes}:${seconds}`
        this.customer.telecash.returnurl = returnurl

        this.customer.telecash.savehosteddataid = assigntoken

        // if we are paying with a vaulted method, we cannot set assigntoken
        if (vaultedpurchase) {
            this.customer.telecash.savehosteddataid = false
            
            let method = this.customer.telecash.methods.find(method => method.psp === PAYMENT_SERVICE_PROVIDERS.TELECASH && method.method === ogmethod)
            if (method) {
                // use the already assgined payment reference
                this.customer.telecash.hosteddataid = method.data.reference
            }
        }

        return getTransactionHash(this.customer.telecash.transactiontime, this.customer.telecash.method, this.customer.telecash.returnurl, vault, amount, this.customer.telecash.hosteddataid, this.customer.telecash.savehosteddataid)
        .then(hash => {
            this.customer.telecash.transactionhash = hash
            return new Promise(res => res(undefined))
        })
        .catch(err => {
            // reset the object and rethrow err for higher level handling
            this.customer.telecash.resetTransactionData()
            throw err
        })
    }

    /**
     * Gets a direct-payment url from LogPay for the given method and amount
     * 
     * @param {PAYMENT_METHODS} ogmethod method that will be used
     * @param {Number} amountincent amount in cent
     * @returns {Promise<String>} the url to redirect to LogPay
     */
    async getLogPayDirectPaymentUrl(ogmethod, amountincent) {
        let logpaymethod = mapToLogPayDirectPaymentMethod(ogmethod)

        if (!logpaymethod) {
            throw new Error('getLogPayDirectPaymentUrl | Unknown payment method: ' + ogmethod)
        }

        const items = [
            {
                id: 'hpp',
                name: 'dummy',
                price: amountincent,
                quantity: 1
            }
        ] // item: { id: xyz, name: xyz, price: 5000, quantity: 1 }

        return getDirectPaymentUrl(logpaymethod, amountincent, items)
    }

    /**
     * Triggers a braintree purchase with a vaulted braintree method
     * if "method.data.methodtoken" is not set, the default method will be used
     * to make the purchase
     * 
     * @param {VaultedMethod} method the method to use
     * @param {Number} amountincent the amount in cent that will be  requested
     * @returns {Promise<Object>} a paypal transaction object that contains the result
     */
    async paypalVaultedPurchase(method, amountincent) {
        const braintreereference = method.data.methodtoken
        const response = await vaultedPurchase(this.customer.braintree.id, braintreereference, amountincent)
        return new Promise(res => res(response.data.transaction))
    }

    /**
     * Triggers a logpay purchase with a vaulted method
     * 
     * @param {VaultedMethod} method the method to use
     * @returns {Promise<Object>} the logpay response object
     */
    async logPayVaultedPurchase(method, amountincent) {
        const logpaymethod = mapToLogPayPaymentMethod(method.method, amountincent)

        const items = [
            {
                id: 'hpp',
                name: 'dummy',
                price: amountincent,
                quantity: 1
            }
        ] // item: { id: xyz, name: xyz, price: 5000, quantity: 1 }

        return vaultedLogPayPurchase(this.customer.logpay.id, logpaymethod, amountincent, items, method.data.reference, method.method)
    }
}

/**
 * Gets the redirect url for LogPay vaulting
 * 
 * @param {PaymentCustomer} customer the customer for which the url will be created
 * @param {String} oidtoken the oidtoken of the customer
 * @param {PAYMENT_METHODS} ogmethod 
 * @returns {Promise<String>} a promise with the redirect url to the LogPay vaulting page
 */
async function vaultLogPay(customer, oidtoken, ogmethod) {
    const logpaymethod = mapToLogPayPaymentMethod(ogmethod)

    if (!logpaymethod) {
        throw new Error('vaultLogPay | Unknown payment method: ' + ogmethod)
    }

    return vaultLogPayMethod(customer.logpay.id, logpaymethod, oidtoken)
    .then(response => {
        return new Promise(res => res(response.data.redirect))
    })
}

/**
 * Unvaults a given method for the given customer
 * 
 * @param {PaymentCustomer} customer the customer whos method will be unvaulted
 * @param {String} oidtoken the oidtoken of the customer
 * @param {PAYMENT_ORIGINS} origin the origin of the method (braintree case)
 * @param {PAYMENT_METHODS} ogmethod the payment method
 * @param {String} reference the TeleCash reference of the method
 * @returns {Promise<?>} a promise with an arbitrary value for Promise chaining. Getting a promise means success
 */
async function removeVaultedTelecash(customer, oidtoken, origin, ogmethod, reference) {
    // TeleCash PayPal
    if (origin === PAYMENT_ORIGINS.BRAINTREE) {
        return await removePaymentMethod(customer.braintree.id)
    }

    // else
    const method = mapInternalToMerchantServerPaymentMethod(ogmethod)

    if (method) {
        return removeTeleCashMethod(oidtoken, reference)
    } else {
        throw new Error('removeVaultedTelecash | unsupported method: ' + method)
    }
}

/**
 * Initializes the braintree account for the given customer
 * - get or creates a braintree customer
 * - sets the braintree reference in the nps
 * 
 * @param {PaymentCustomer} customer - NPS customer which is associated with the braintree account
 * @param {PAYMENT_SERVICE_PROVIDERS} psp - TeleCash or LogPay
 * @param {Boolean} [createuser = false] - default: false, create the user if it does not exist
 * @returns {Promise<PaymentCustomer>} the customer with the added braintree information
 */
async function initBraintree(customer, oidtoken, psp, createuser = false) {
    let npspsp

    if (psp === PAYMENT_SERVICE_PROVIDERS.TELECASH) {
        npspsp = 'TeleCash'
    } else if (psp === PAYMENT_SERVICE_PROVIDERS.LOGPAY) {
        npspsp = 'LogPay'
    } else {
        throw new Error('unkown braintree psp: ' + psp)
    }

    let braintreeref = customer?.profile?.lists?.paymentReferences?.find(ref => {
        let result = true
        result = result && ref.paymentMethodDescription === BRAINTREE_PSP_NAME
        result = result && ref.paymentServiceProvider   === npspsp
        return result
    })

    if (braintreeref && braintreeref.paymentMethodReference) {
        customer.braintree.id = braintreeref.paymentMethodReference
    }

    return getBraintreeCustomer(customer.braintree.id)
    .then(async braintreecustomer => {

        if (braintreecustomer) {
            customer.braintree.methods = braintreecustomer.paymentMethods
        } else if (!braintreecustomer && createuser) {
            braintreecustomer = await createBraintreeCustomer(customer.firstname, customer.lastname, customer.email, customer.profile, oidtoken, npspsp)
            customer.braintree.id = braintreecustomer.braintree.id
        }

        return new Promise((res) => res(customer))
    })
}

/**
 * Initializes everything needed for LogPay Vaulting and payments for the given customer
 * - get or creates a logpay customer
 * - creates a logpay reference in the nps
 * - and or maps loaded references to the given customer
 * 
 * @param {PaymentCustomer} customer - NPS customer which is associated with the logpay account
 * @param {String} oidtoken - oidtoken of the nps user
 * @param {Boolean} [createuser = false] - default: false, create the user if it does not exist
 * @returns {Promise<PaymentCustomer>} the customer with the added logpay information
 */
async function initLogPay(customer, oidtoken, createuser = false) {
    // in the case of logpay every method is stored in the logpay user
    // so we only have the reference to it, not to the methods themselves
    // --> there is a braintree ref too! so we need the one without the payment method/description
    const logpayref = customer?.profile?.lists?.paymentReferences?.find(ref => ref.paymentServiceProvider === 'LogPay' && !ref.paymentMethod)

    if (logpayref && logpayref.customerReference) {
        customer.logpay.id = logpayref.customerReference
    }

    // get or create braintree user
    // this step is separate beacuse the paymentPage only has to load the user
    return getLogPayCustomer(customer.logpay.id)
    .then(logpayuser => {
        if (!logpayuser && createuser) {
            // logpay needs another dateformat
            const birthdatestring  = customer.profile?.properties?.personalInformation?.dateOfBirth
            const parts = birthdatestring?.split('.')

            const email      = customer.email
            const firstname  = customer.firstname
            const lastname   = customer.lastname
            const street     = customer.address.strasse
            const housenr    = customer.address.hausNr + (customer.address.hausNrZusatz || '')
            const zip        = customer.address.plz
            const city       = customer.address.ort
            const country    = customer.address.land
            const birthdate  = parts ? `${parts[2]}-${parts[1]}-${parts[0]}` : undefined
            const internalid = oidtoken

            // this function takes so many params on purpose!
            // they are all required and it's not used often
            return createLogPayUser(email, firstname, lastname, birthdate, street, housenr, zip, city, internalid, country)
            .then(result => {
                // save new logpay userid
                customer.logpay.id = result.id

                return createOrUpdateLogPayReference(oidtoken, customer.profile, customer.logpay.id)
            })
            .then(() => {
                return new Promise((res) => res(customer))
            })
        } else if (logpayuser) {
            // since logpay handles things differently as expected
            // we fake an nps ref to use existing logic
            logpayuser.methods?.forEach(method => {
                const fakeref = {
                    paymentServiceProvider:  'LogPay',
                    paymentMethod:            method.type,
                    paymentMethodDescription: method.label,
                    paymentMethodReference:   method.id,
                    favourite: method.favorite?.toLowerCase() === 'y'
                }

                const vaultedmethod = mapNpsRefrenceToVaultedMethod(fakeref)

                customer.logpay.methods.push(vaultedmethod)
            })

            return new Promise((res) => res(customer))
        } else {
            throw 'no-logpay-user'
        }
    })
}

/**
 * Maps the nps profile methods to the telecash part of the given customer
 * 
 * @param {PaymentCustomer} customer
 * @returns {PaymentCustomer} The updated customer
 */
function initTeleCash(customer) {
    // in the case of telecash we only need to map the correct payment methods
    customer.profile.lists?.paymentReferences?.forEach(ref => {
        if (ref.paymentServiceProvider === 'TeleCash') {
            const vaultedmethod = mapNpsRefrenceToVaultedMethod(ref, customer.braintree)

            customer.telecash.methods.push(vaultedmethod)
        }
    })

    return customer
}

/**
 * Maps an internal method [PAYMENT_METHODS] to the corresponding
 * Merchant Server method string
 * 
 * @param {PAYMENT_METHODS} method
 * @returns {String} Merchant Server method
 */
function mapInternalToMerchantServerPaymentMethod(method) {
    let merchantservermethod = undefined

    switch(parseInt(method, 10)) {
        case PAYMENT_METHODS.SEPA:
            merchantservermethod = 'DirectDebit'
            break;
        case PAYMENT_METHODS.PAYPAL:
            merchantservermethod = 'PayPal'
            break;
        case PAYMENT_METHODS.VISA:
            merchantservermethod = 'Visa'
            break;
        case PAYMENT_METHODS.MASTERCARD:
            merchantservermethod = 'Mastercard'
            break;
        default:
            break;
    }

    return merchantservermethod
}

/**
 * Maps an internal method to the corresponding LogPay method string
 * 
 * @param {PAYMENT_METHODS} method the method to be mapped
 * @returns {"POSTPP" | "POSTCC" | "POSTDD"} the corresponding LogPay method string
 */
function mapToLogPayPaymentMethod(method) {
    let logpaymethod = undefined

    switch(method) {
        case PAYMENT_METHODS.PAYPAL:
            logpaymethod = 'POSTPP'
            break;
        case PAYMENT_METHODS.VISA:
            logpaymethod = 'POSTCC'
            break;
        case PAYMENT_METHODS.MASTERCARD:
            logpaymethod = 'POSTCC'
            break;
        case PAYMENT_METHODS.SEPA:
            logpaymethod = 'POSTDD'
            break;
        default:
            break;
    }

    return logpaymethod
}

/**
 * Maps an internal method to the corresponding LogPay method string used for direct payments
 * 
 * @param {PAYMENT_METHODS} method the method to be mapped
 * @returns {"PASSCC" | "GIRPAY" | "PAYPAL" | "GOOGLE" | "APPLEP"} the corresponding LogPay method string for direct payments
 */
function mapToLogPayDirectPaymentMethod(method) {
    let logpaymethod = undefined
    
    switch(method) {
        case PAYMENT_METHODS.MASTERCARD:
        case PAYMENT_METHODS.VISA:
            logpaymethod = 'PASSCC'
            break;
        case PAYMENT_METHODS.SEPA:
            logpaymethod = 'GIRPAY'
            break;
        case PAYMENT_METHODS.PAYPAL:
            logpaymethod = 'PAYPAL'
            break;
        case PAYMENT_METHODS.GOOGLEPAY:
            logpaymethod = 'GOOGLE'
            break;
        case PAYMENT_METHODS.APPLEPAY:
            logpaymethod = 'APPLEP'
            break;
        default:
            break;
    }

    return logpaymethod
}

/**
 * Maps the internal method to a TeleCash method string
 * 
 * @param {PAYMENT_METHODS} method to be mapped to a telecash method string
 * @returns {"V" | "M" | "debitDE" | "googlePay" | "applePay"} TeleCash payment method
 */
function mapToTeleCashPaymentMethod(method) {
    let telecashmethod = undefined

    switch(method) {
        case PAYMENT_METHODS.VISA:
            telecashmethod = 'V'
            break;
        case PAYMENT_METHODS.MASTERCARD:
            telecashmethod = 'M'
            break;
        case PAYMENT_METHODS.SEPA:
            telecashmethod = 'debitDE'
            break;
        case PAYMENT_METHODS.GOOGLEPAY:
            telecashmethod = 'googlePay'
            break;
        case PAYMENT_METHODS.APPLEPAY:
            telecashmethod = 'applePay'
            break;
        default:
            break;
    }

    return telecashmethod
}

/**
 * Maps a nps payment method reference object to an internal vaulted method
 * 
 * @param {Object} npsreference the nps object which will be mapped to a vaulted method
 * @param {BraintreeCustomer} braintreecustomer 
 * @returns {VaultedMethod} the vaulted method { origin, psp, method, data }
 */
function mapNpsRefrenceToVaultedMethod(npsreference, braintreecustomer = undefined) {
    let psp    = undefined
    let data   = undefined
    let origin = undefined
    let method = undefined

    switch(npsreference.paymentServiceProvider) {
        case 'PayPal': {
            psp    = PAYMENT_SERVICE_PROVIDERS.PAYPAL
            origin = PAYMENT_ORIGINS.PAYPAL
            data   = {
                description: npsreference.paymentMethodDescription,
                reference: npsreference.paymentMethodReference
            }
            break
        }
        case 'TeleCash': {
            method = mapMerchantServerToInternalPaymentMethod(npsreference.paymentMethod)
            psp    = PAYMENT_SERVICE_PROVIDERS.TELECASH

            if (npsreference.paymentMethodDescription === BRAINTREE_PSP_NAME) {
                if (braintreecustomer.methods && braintreecustomer.methods.length > 0) {
                    origin = PAYMENT_ORIGINS.BRAINTREE
                    data   = {
                        description: braintreecustomer.methods?.find(method => method.default).email,
                        reference: npsreference.paymentMethodReference,
                        // in case of braintree paypal for telecash we need the token of the method to use
                        // otherwise the default method will be used
                        methodtoken: braintreecustomer.methods?.find(method => method.default).token
                    }
                } else {
                    // this means the braintree account is empty, therefore we prevent
                    // the method from being added
                    psp = undefined
                }
            } else if (npsreference.paymentMethodDescription !== BRAINTREE_PSP_NAME) {
                origin = PAYMENT_ORIGINS.TELECASH
                data   = {
                    description: npsreference.paymentMethodDescription,
                    reference: npsreference.paymentMethodReference
                }
            }
            break
        }
        case 'LogPay': {
            method = mapLogPayToInternalPaymentMethod(npsreference.paymentMethod, npsreference.paymentMethodDescription)
            psp    = PAYMENT_SERVICE_PROVIDERS.LOGPAY
            origin = PAYMENT_ORIGINS.LOGPAY

            // cut away method descriptor and method id
            // MST / ******1234 / 567890 -> we only want the middle part
            // PayPal BillingAgreement / user@email.com / 123456
            let description = npsreference.paymentMethodDescription
            
            // --> SEPA/IBAN has a different format, so we need to catch that
            const descriptionarray = description?.split('/') || []
            
            if (descriptionarray.length > 1) {
                description = descriptionarray[1].trim()
            }

            data   = {
                description: description,
                reference: npsreference.paymentMethodReference,
                favourite: npsreference.favourite
            }
            break;
        }
        default:
            break
    }

    return new VaultedMethod(origin, psp, method, data)
}

/**
 * Maps a merchant server/backend string to the corresponding internal method
 * 
 * @param {"PayPal" | "Visa" | "Mastercard" | "DirectDebit" | "GooglePay" | "ApplePay"} method the merchant server method string
 * @returns {PAYMENT_METHODS} the internal method
 */
function mapMerchantServerToInternalPaymentMethod(method) {
    let internalmethod = undefined

    switch(method) {
        case 'PayPal':
            internalmethod = PAYMENT_METHODS.PAYPAL
            break;
        case 'Visa':
            internalmethod = PAYMENT_METHODS.VISA
            break;
        case 'Mastercard':
            internalmethod = PAYMENT_METHODS.MASTERCARD
            break;
        case 'DirectDebit':
            internalmethod = PAYMENT_METHODS.SEPA
            break;
        case 'GooglePay':
            internalmethod = PAYMENT_METHODS.GOOGLEPAY
            break;
        case 'ApplePay':
            internalmethod = PAYMENT_METHODS.APPLEPAY
            break;
        default:
            break;
    }

    return internalmethod
}


/**
 * Maps a LogPay method string to an internal method
 * -> used the LogPay method discription to distinct VISA and MASTERCARD credit cards
 * 
 * @param {"POSTDD" | "POSTCC" | "POSTPP"} method the LogPay method string
 * @param {String} label the logpay method description to distinct credit cards
 * @returns {PAYMENT_METHODS} the internal method
 */
// all available types: "POSTDD" | "POSTCC" | "POSTPP" | "PREPAY" | "SLFPAY" | "POSTAP" | "POSTPD"
function mapLogPayToInternalPaymentMethod(method, label) {
    let internalmethod = undefined

    switch(method) {
        case 'POSTDD':
            internalmethod = PAYMENT_METHODS.SEPA
            break;
        case 'POSTCC':
            if (label.toLowerCase().startsWith('mst')) {
                internalmethod = PAYMENT_METHODS.MASTERCARD
            } else if (label.toLowerCase().startsWith('vis')) {
                internalmethod = PAYMENT_METHODS.VISA
            }
            break;
        case 'POSTPP':
            internalmethod = PAYMENT_METHODS.PAYPAL
            break;
        default:
            break;
    }

    return internalmethod
}

export function mapInternalToBackendPaymentMethod(ogmethod) {
    switch(ogmethod) {
        case PAYMENT_METHODS.PAYPAL:
            return BACKEND_PAYMENT_METHOD.bd_PayPal
        case PAYMENT_METHODS.SEPA:
            return BACKEND_PAYMENT_METHOD.bd_Paydirekt
        case PAYMENT_METHODS.MASTERCARD:
            return BACKEND_PAYMENT_METHOD.bd_Mastercard
        case PAYMENT_METHODS.VISA:
            return BACKEND_PAYMENT_METHOD.bd_Visa
        case PAYMENT_METHODS.GOOGLEPAY:
            return BACKEND_PAYMENT_METHOD.bd_GooglePay
        case PAYMENT_METHODS.APPLEPAY:
            return BACKEND_PAYMENT_METHOD.bd_ApplePay
    }
}