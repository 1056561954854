import Vue from 'vue'

const mixin = {
    methods: {
        _leadingZero: function(digit) {
            return ('0' + digit).slice(-2)
        },
        _getCurrencySymbol: function(currency) {
            switch(currency) {
                case 'USD':
                    return '$'
                case 'EUR':
                default:
                    return '€'
            }
        },
        _currencyValueToFloat: function(value) {
            return (value/100).toFixed(2)
        },
        _rng: function(length) {
            // generate a random string with a given length
            let result       = ''
            const characters = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz0123456789'
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * characters.length))
            }
            return result
        },
        _formatDate(date) {
            if (!(date instanceof Date)) {
                date = new Date(date)
            }
            return [
                this._leadingZero(date.getDate()),
                this._leadingZero(date.getMonth() + 1),
                date.getFullYear(),
            ].join('.');
        },
        _getTransitionDuration(element) {
            // unset duration === 0s, so we dont need any null/unset checks
            let transitionduration = window.getComputedStyle(element).transitionDuration

            if (transitionduration.indexOf('s')) {
                transitionduration = transitionduration.substring(0, transitionduration.length -1)
                transitionduration = transitionduration * 1000
            } else if (transitionduration.indexOf('ms')) {
                transitionduration = transitionduration.substring(0, transitionduration.length -1)
            }

            return transitionduration
        },
        _returnPaymentResult({ error, success, paymentid, psp, paymentmethod, location, oidtoken } = {}) {
            const baseUrl = location.origin + location.pathname

            let redirectUrl = baseUrl + '?'

            if (error) {
                // we want the error object as it came in to be logged
                // stringifying it prevents the browser from updating/losing the object reference
                const errorstring = error.message || JSON.stringify(error)
                redirectUrl += 'error=' + errorstring
            } else {
                redirectUrl += 'success=' + success
                redirectUrl += paymentid     ? '&oid='   + paymentid     : ''
                redirectUrl += psp           ? '&psp='   + psp           : ''
                redirectUrl += paymentmethod ? '&pm='    + paymentmethod : ''
                redirectUrl += oidtoken      ? '&token=' + oidtoken      : ''
            }

            location.replace(redirectUrl)
        }
    }
}

Vue.mixin(mixin)

export default mixin